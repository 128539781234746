import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "./technologyPageHeader.css";
import { moveToTechnologyPage } from "../../actions/helper";

const TechnologyHeader = (props) => {
  const selectedTechnology = props.selectedTechnology
  const technologyOverview = props.technologyOverview

  const navigate = useNavigate();

  return (
    <div>
      <hr className="mt-0"/>

      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="6">
          <div className="technologyMonitor">
            <div>
              {selectedTechnology ? (
                <h1 style={{ fontSize: "1.3rem", marginLeft: "11px"}}>
                  {selectedTechnology.definition}
                </h1>
              ) : (
                <Skeleton />
              )}
            </div>
            {technologyOverview?
            <h2 className="details-title">
                 {technologyOverview.total_applications} patents filed | {technologyOverview.country_analysis.total_countries} countries working 
                  | {technologyOverview.assignee_analysis.total_assignees} active organizations
            </h2>
            :
            <h2>
              <Skeleton/>
            </h2>
            }
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="6" style={{display: "flex"}}>
        <div className="sectionContainer">
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "overview"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"overview",[selectedTechnology])}
            >
              Overview
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "dataset"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"dataset",[selectedTechnology])}
            >
              Dataset
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "trends"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"trends",[selectedTechnology])}
            >
              Trends
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "global"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"global",[selectedTechnology])}
            >
              Global
            </div>
            {/* <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "organization"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"organization")}
            >
              Organization
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TechnologyHeader;
