import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import LineChart from "../../components/Charts/lineChart";
import PieChart from "../../components/Charts/pieChart";
import { Tracking } from "../../components/tracking/tracking";

const Trends = (props) => {
  const [keyPlayers, setKeyPlayers] = useState(
    props.keyPlayers ? props.keyPlayers : []
  );
  const [grantPercent, setGrantPercent] = useState(
    props.grantPercent ? props.grantPercent : undefined
  );
  const [researchYear, setResearchYear] = useState(
    props.topYear ? props.topYear : undefined
  );
  const [researchYearContribution, setResearchYearContribution] = useState(
    props.topYearContribution ? props.topYearContribution : undefined
  );
  const [trends, setTrends] = useState(props.trends ? props.trends : undefined);

  useEffect(() => {
    setKeyPlayers(props.keyPlayers ? props.keyPlayers : []);
    setGrantPercent(props.grantPercent ? props.grantPercent : undefined);
    setResearchYear(props.topYear ? props.topYear : undefined);
    setResearchYearContribution(
      props.topYearContribution ? props.topYearContribution : undefined
    );
    setTrends(props.trends ? props.trends : undefined);
  }, [props]);

  return (
    <div>
      <Tracking
        title={"Research overview for :" + props.query}
        description={"Patents in " + props.query}
        keywords={props.query}
      />
      <Row className="g-0">
        <Col xs="12" md="6" className="borderRight">
          <p className="keyPoints">Key Trends</p>
          <p className="para" style={{ fontWeight: "bold", marginTop: "20px" }}>
            Recently in the year {researchYear}, {researchYearContribution}{" "}
            patents were filed.
          </p>
          {keyPlayers[0] && (
            <div className="trendText" style={{ marginTop: "10px" }}>
              <ul className="keyPointsUL">
                <li style={{ marginBottom: "10px" }}>
                  {typeof grantPercent !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {grantPercent}%
                      </strong>{" "}
                      of the patent applications filed are granted.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li style={{ marginBottom: "10px" }}>
                  {typeof researchYear !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {researchYear}
                      </strong>{" "}
                      observed a high research activity.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li style={{ marginBottom: "10px" }}>
                  {typeof researchYearContribution !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {researchYearContribution}
                      </strong>{" "}
                      patents were filed in{" "}
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {researchYear}
                      </strong>{" "}
                      year.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
              </ul>
            </div>
          )}
          <div className="trendGraph">
            {grantPercent && (
              <PieChart
                labels={["Granted", "Pending"]}
                values={[grantPercent, 100 - grantPercent]}
                label="Patents status"
                width="400px"
                height="auto"
              />
            )}
          </div>
        </Col>
        <Col xs="12" md="6">
          {typeof trends != "undefined" && (
            <div className="trendsGraph1">
              <LineChart
                labels={trends.labels}
                series={trends.count.map((item) => {
                  return item[0];
                })}
                name={"Patents filed"}
                title={"YoY patent filing trends"}
              />
            </div>
          )}
          {typeof trends != "undefined" && (
            <div className="trendsGraph2">
              <LineChart
                labels={trends.labels}
                series={trends.count.map((item) => {
                  return item[1];
                })}
                name={"Patents published"}
                title={"YoY patent publication trends"}
                axisLabel={"Patents Published"}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Trends;
