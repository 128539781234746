import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import "./searchSection.css";
import { useNavigate } from "react-router-dom";
import { Logo } from "../logo/logo";
import { moveToResultsPage } from "../../actions/helper";
import { FaSearch } from "react-icons/fa";

export const SearchSection = () => {
  const [query, setQuery] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [typedPlaceholder, setTypedPlaceholder] = useState("");

  const placeholders = [
    "nanotechnology",
    "cybernetics",
    "data science",
    "neuroscience",
    "space exploration",
    "material science",
    "economics & finance",
    "education",
  ];

  useEffect(() => {
    const placeholderText = placeholders[placeholderIndex];
    let currentPlaceholder = "";

    const interval = setInterval(() => {
      if (currentPlaceholder.length < placeholderText.length) {
        currentPlaceholder += placeholderText[currentPlaceholder.length];
        setTypedPlaceholder(currentPlaceholder);
      } else {
        clearInterval(interval);
        setTimeout(() => {
          setPlaceholderIndex(
            (prevIndex) => (prevIndex + 1) % placeholders.length
          );
        }, 2000);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [placeholderIndex]);

  const navigate = useNavigate();

  return (
    <Container className="searchSection">
      <Row>
        <Col xs="12">
          <div className="searchBox">
            <div className="searchDiv landingSearchDiv">
              <div className="landingBrandname">
                Do
                <br />
                Your
                <br />
                Research
              </div>
              {/* <div className="searchBarWrapper">
                <input
                  className="searchBar"
                  placeholder={typedPlaceholder}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") moveToResultsPage(navigate, query);
                  }}
                />
                  <FaSearch className="searchIcon" style={{top: "0px"}} onClick={(e) =>{ moveToResultsPage(navigate, query);
                }}  />
              </div> */}
              <p className="landingPara brandNamePara" style={{color: "white", marginTop: "10px"}}>For academic and industry professionals</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
