import React from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { LandingPage } from './pages/landingPage/landingPage';
import SearchResult from './pages/searchResults/searchResults';
import PatentView from './pages/patentView/patentView';
import { SignUp } from './pages/signUp/signUp';
import TechnologyPage from './pages/technologyPage/technologyPage';
import CountryPage from './pages/countryPage/countryPage';
import CompanyPage from './pages/companyPage/companyPage';
import ForYou from './pages/forYouPage/forYou';
import Reports from './pages/reports/reports';
import { SignIn } from './pages/signIn/signIn';
import { AdminPage } from './pages/adminPage/adminPage';
import OYI from './pages/oyiPage/oyiPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}/>
        <Route path="/results" element={<SearchResult />}/>
        <Route path="/results/:section" element={<SearchResult />}/>
        <Route path="/patent/:number" element={<PatentView/>}/>
        <Route path="/patent/:section/:number" element={<PatentView/>}/>
        <Route path="/sign-up" element={<SignUp/>}/>
        <Route path="/sign-in" element={<SignIn/>}/>
        
        <Route path="/company-page/:assignee/:section" element={<CompanyPage/>}/>
        <Route path="/technology-page" element={<TechnologyPage/>} />
        <Route path="/technology-page/:section" element={<TechnologyPage/>} />
        <Route path="/technology-page/:cpc/:section" element={<TechnologyPage/>} />
        <Route path="/country-page" element={<CountryPage/>} />
        <Route path="/for-you/:section" element={<ForYou/>} />
        <Route path="/for-you/request-report/:report" element={<ForYou/>} />
        <Route path="/reports/:id" element={<Reports/>} />
        <Route path="/own-your-invention/:section" element={<OYI/>} />   
        <Route path="/admin/:section" element={<AdminPage/>} />               
      </Routes>
    </BrowserRouter>
  );
}

export default App;
