import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import "./patentView.css";
import "../../styles/fontsizes_patentpage/fontSize.css";
import { Footer } from "../../components/footer/footer";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";

import PatentViewHeader from "../../sections/patentViewHeader/patentViewHeader";
import PatentAbstract from "../../sections/patentAbstract/patentAbstract";
import PatentClaim from "../../sections/patentClaim/patentClaim";
import PatentTechnology from "../../sections/patentTechnology/patentTechnology";
import PatentRecommendation from "../../sections/patentRecommendation/patentRecommendation";
import { saveUserPreference, toTitleCase } from "../../actions/helper";

const PatentView = (props) => {
  const location = useLocation();

  const [publicationNumber, setPublicationNumber] = useState(
    decodeURIComponent(window.location.pathname.split("/").at(-1))
  );
  const [flag, setFlag] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [industry, setIndustry] = useState();
  const [sector, setSector] = useState();
  const [subSector, setSubSector] = useState();
  const [researchArea, setResearchArea] = useState();
  const [cpc, setCPC] = useState();
  const [patentFields, setPatentFields] = useState();
  const [patentTitle, setPatentTitle] = useState();
  const [abstract, setAbstract] = useState();
  const [description,setDescription] = useState();
  const [claims, setClaims] = useState();
  const [claimsText, setClaimsText] = useState();
  const [applicationDate, setApplicationDate] = useState();
  const [publicationDate, setPublicationDate] = useState();
  const [grantDate, setGrantDate] = useState();
  const [expiryDate, setExpiryDate] = useState("");
  const [status, setStatus] = useState("Pending");
  const [priorityDate, setPriorityDate] = useState("");
  const [inventors, setInventors] = useState([""]);
  // const [downloadingFlag, setDownloadingFlag] = useState(true);

  const [similarPatents, setSimilarPatents] = useState([]);
  const [relatedPatents, setRelatedPatents] = useState([]);
  const [similarOrgs, setSimilarOrgs] = useState([]);

  const [notFoundFlag,setNotFoundFlag] = useState(false)
  const [loading, setLoading] = useState(true);

  // research area of patent
  const [technologyOverview, setTechnologyOverview] = useState({});
  const [researchAreaTrends, setResearchAreaTrends] = useState();


  const xSearchURL =
    "https://hiykgsfdzd.execute-api.us-east-1.amazonaws.com/production/xsearch";
  const getSimilarPatentURL =
    "https://5cice31m1c.execute-api.us-east-1.amazonaws.com/production/apis/bulk-download/applications";
  const getSimilarOrgs =
    "https://09rypb92vg.execute-api.ap-south-1.amazonaws.com/alpha/api/v1/competition";
  const getPatentStatusURL =
    " https://09rypb92vg.execute-api.ap-south-1.amazonaws.com/alpha/api/v1/status";
  const technologyOverviewURL = 
    "https://api.incubig.org/analytics/technology-analysis"
  // const downloadApplicationURL = "http://localhost:8000/download/patent"
  const downloadApplicationURL =
    "https://api.incubig.org/download/patent-pdf";
  const citationAPI = "https://api.patentsview.org/patents/query";

  // const getApplicationAPI = "http://localhost:7000/download/patent"
  const getApplicationAPI = "https://api.incubig.org/download/patent";
  const cpcDefinitionAPI = "https://api.incubig.org/analytics/cpc-definition";

  useEffect(() => {
    clearState();
    let tempPublicationNumber = decodeURIComponent(window.location.pathname.split("/").at(-1))
    
    if (
      tempPublicationNumber.startsWith("IN") |
      tempPublicationNumber.startsWith("US") |
      tempPublicationNumber.startsWith("RU")
    ) {
      tempPublicationNumber = tempPublicationNumber;
    }
    else if (tempPublicationNumber.includes("/")) {
      tempPublicationNumber = "IN"+tempPublicationNumber;
    }
    else {
      tempPublicationNumber = "US" + tempPublicationNumber + "A1";
    }
    setPublicationNumber(tempPublicationNumber);
    
    getPatent(tempPublicationNumber);
    getPatentStatus(window.location.pathname.split("/").at(-1));

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [window.location.pathname.split("/").at(-1)]);

  const getPatent = (pn) => {
    setNotFoundFlag(false)
    axios
      .get(getApplicationAPI, {
        headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"}, 
        params: { publication_number: pn },
        "Content-Type": "application/xml; charset=utf-8",
      })
      .then((response) => {
        if (response.data.status_code === 404) {
          setNotFoundFlag(true)
        }
        let patentData = response.data.data;

        if (patentData.publication_number) {
          setFlag(true);
          setPublicationDate(
            moment(patentData.publication_date, "YYYYMMDD").format(
              "MMM Do YYYY"
            )
          );
          setApplicationDate(
            moment(patentData.application_date, "YYYYMMDD").format(
              "MMM Do YYYY"
            )
          );
          setPriorityDate(
            patentData.priority_date
              ? moment(patentData.priority_date, "YYYYMMDD").format(
                  "MMM Do YYYY"
                )
              : moment(patentData.application_date, "YYYYMMDD").format(
                  "MMM Do YYYY"
                )
          );

          setPatentTitle(patentData.title);
          if (patentData.assignee){
            if (patentData.assignee.constructor === Array) {
              setAssignees(patentData.assignee);
            } else if (patentData.assignee.constructor === String) {
              setAssignees(
                patentData.assignee
                  .substr(1, patentData.assignee.length - 2)
                  .split(",")
              );
            } else {
              setAssignees();
            }
        }
        else if (patentData.assignees){
          if (patentData.assignees.constructor === Array) {
            setAssignees(patentData.assignees);
          } else if (patentData.assignees.constructor === String) {
            setAssignees(
              patentData.assignees
                .substr(1, patentData.assignees.length - 2)
                .split(",")
            );
          } else {
            setAssignees();
          }
        }

          setInventors(patentData.inventor);
          setIndustry(patentData.industry);
          if (patentData.sector) {
            setSector(patentData.sector.replace(/[^a-zA-Z ]/g, ""));
          } else {
            setSector(patentData.industry);
          }
          if (patentData.subSector) {
            setSubSector(patentData.sub_sector.replace(/[^a-zA-Z ]/g, ""));
          } else {
            setSector(patentData.industry);
          }

          setCPC(patentData.main_cpc);
          if (patentData.further_cpc){
            saveUserPreference([patentData.main_cpc].concat(patentData.further_cpc))
            axios
            .post(
              cpcDefinitionAPI,
              (patentData.further_cpc.includes(patentData.main_cpc)?patentData.further_cpc:patentData.further_cpc.concat([patentData.main_cpc]))
            )
            .then((fields) => {
              setPatentFields(fields.data);
              setResearchArea(
                fields.data.filter((item) => item.cpc == patentData.main_cpc)[0]
                  .definition
              );
            });
          }
          else {
            saveUserPreference([patentData.main_cpc].concat(patentData.cpc))
            axios
            .post(
              cpcDefinitionAPI,
              patentData.cpc
            )
            .then((fields) => {
              setPatentFields(fields.data);
              setResearchArea(
                fields.data.filter((item) => item.cpc == patentData.main_cpc)[0]
                  .definition
              );
            });
          }

          setPatentTitle(patentData.title);
          getSimilarPatents(patentData.title);
          
          if (patentData.abstract_text) {
            setAbstract(patentData.abstract_text);
          } else if (patentData.abstract) {
            setAbstract(patentData.abstract);
          } else {
            setAbstract();
          }
          
          setDescription(patentData.description_text)
          setClaims(patentData.claims);
          if (patentData.claims_text&&patentData.claims_text.constructor===Array){
            setClaimsText(patentData.claims_text.join("CLM-"))
          }
          else if (patentData.claims_text){
            setClaimsText(patentData.claims_text.replace("\n", ""));
          }
          // console.log(patentData)
          axios
            .get(getSimilarOrgs, {
              params: { cpc: patentData.main_cpc.split("/")[0] },
            })
            .then((resp) => {
              setSimilarOrgs(resp.data);
            });
          axios
            .get(xSearchURL, {
              params: {
                query: patentData.industry,
                filter:
                  "(assignee/any(p:p eq '" + patentData.assignee[0] + "'))",
              },
            })
            .then((res) => {
              setRelatedPatents(res.data.results);
            });
          axios.get(technologyOverviewURL, { headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"},params: { cpc: patentData.main_cpc}}).then((res)=>{
            // console.log(patentData.main_cpc)
            // console.log({"labels":res.data.application_trends.map((item)=>{return item.year}),"count":res.data.application_trends.map((item)=>{return [item.count,0]})})
            setResearchAreaTrends({"labels":res.data.application_trends.map((item)=>{return item.year}),"count":res.data.application_trends.map((item)=>{return [item.count,0]})})
            
            let bestYear = res.data.application_trends.reduce((prev, curr)=> {return (prev.count>curr.count)?prev:curr},{application_trends:[]})
            
            setTechnologyOverview({
              bestYear: bestYear.year,
              bestYearCount: bestYear.count,
              topCountry: res.data.country_analysis.top_countries[0].country,
              laggingCountry: res.data.country_analysis.top_countries[0].country,
              totalCountries: res.data.country_analysis.total_countries,
              topAssignee: res.data.assignee_analysis.top_assignees[0].assignee,
              topAssigneeCount: res.data.assignee_analysis.top_assignees[0].count
            })
            setSimilarPatents(res.data.applications)
          })
          
        } else {
          setFlag(false);
        }
      });
  };

  const getPatentStatus = (pn) => {
    axios
      .get(getPatentStatusURL, { params: { publication_number: pn } })
      .then((response) => {
        // console.log(response.data)
        setGrantDate(response.data.grant_date);
        setExpiryDate(response.data.expiry_date);
        setStatus(
          response.data.status === "Active" ? "Granted" : response.data.status
        );
        if (response.data.patent_number) {
          let query = {
            patent_id: response.data.patent_number.slice(
              2,
              response.data.patent_number.length - 2
            ),
          };
          // axios.get(citationAPI + "?q=" + JSON.stringify(query)).then((res) => {
          //   // console.log(res.data);
          // });
        }
      });
  };

  const getSimilarPatents = (title) => {
    axios.get(xSearchURL, { params: { query: title } }).then((response) => {
      setSimilarPatents(response.data.results);
    });
  };

  const navigate = useNavigate();


  const clearState = () => {
    setFlag(false);
    setAssignees();
    setIndustry();
    setResearchArea();
    setResearchAreaTrends();
    setCPC();
    setPatentFields();
    setAbstract();
    setClaims();
    setClaimsText();
    setStatus("Pending");
    setApplicationDate();
    setPublicationDate();
  };

  return (
    <div className="patentPage">
      <Row className="g-0">
        <Col xs="12">
          <PersonalizedHeader />
        </Col>
      </Row>
      <hr className="thin-horizontal-line" style={{marginTop: "0px", height: "0.5px"}} />
      
      <PatentViewHeader
        flag={flag}
        publicationNumber={publicationNumber}
        title={patentTitle}
        inventor={inventors[0]}
        industry={industry}
        applicationDate={applicationDate}
        cpc={cpc}
      />

      <div>
        <hr className="thin-horizontal-line" />

        <span className="headingPatent" style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.75rem",
              background: "linear-gradient(to right, #1834dd, #d079df)",
              color: "white",
              height: "40px",
            }}>
          {(!notFoundFlag)
          ?
          <p style={{fontSize: "0.8rem"}}>Patent View &nbsp; &#124; {toTitleCase(window.location.pathname.split("/").at(-2))}</p>
          :
          <>
            {(localStorage.getItem("user"))?
            <p style={{fontSize: "0.8rem"}}>The selected patent is not part of your subscription</p>
            :
            <p style={{fontSize: "0.8rem"}}>You need to sign up, to get access to recently published patents.</p>
            }
          </>
          }
        </span>

        <hr className="thin-horizontal-line" />
        {!notFoundFlag&&(window.location.pathname.includes("/patent/about") ||
          window.location.pathname.split("/").at(-2)=="patent") ? (
          <PatentAbstract
            flag={flag}
            publicationNumber={publicationNumber}
            title={patentTitle}
            status={status}
            applicationDate={applicationDate}
            publicationDate={publicationDate}
            assignees={assignees}
            inventors={inventors}
            grantDate={grantDate}
            expiryDate={expiryDate}
            abstract={abstract}
            industry={industry}
            cpc={cpc}
          />
        ) : (
          <div></div>
        )}

        {!notFoundFlag&&(window.location.pathname.includes("/patent/claims") ||
          window.location.pathname.split("/").at(-2)=="patent") ? (
          <PatentClaim
            publicationNumber={publicationNumber}
            title={patentTitle}
            claimsText={claimsText}
            claims={claims}
            description={description}
          />
        ) : (
          <div></div>
        )}

        {!notFoundFlag&&(window.location.pathname.includes("/patent/technology") ||
          window.location.pathname.split("/").at(-2)=="patent") ? (
          <PatentTechnology
            publicationNumber={publicationNumber}
            title={patentTitle}
            researchArea={researchArea}
            industry={industry}
            technologyOverview={technologyOverview}
            researchAreaTrends={researchAreaTrends}
            patentFields={(patentFields&&patentFields.length >4)?patentFields.slice(0,4):patentFields}
          />
        ) : (
          <div></div>
        )}

        {!notFoundFlag&&(window.location.pathname.includes("/patent/similar") ||
          window.location.pathname.split("/").at(-2)=="patent") ? (
          <PatentRecommendation
            publicationNumber={publicationNumber}
            title={patentTitle}
            patentFields={(patentFields&&patentFields.length >4)?patentFields.slice(0,4):patentFields}
            similarApplications = {similarPatents}
          />
        ) : (
          <div></div>
        )}
      </div>

      <Row className="g-0">
        <Col xs="12">
          <div style={{height: "20px"}}>

          </div>
        </Col>
      </Row>

      <Row className="g-0">
        <Col>
          <Footer />
        </Col>
      </Row>
    </div>
  );
};

export default PatentView;
