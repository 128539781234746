import React, { useState } from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import "../../pages/forYouPage/forYou.css"; 
import "../../components/patentsList/patentList.css"
import { moveToPatentPage, toTitleCase, truncateText } from '../../actions/helper';
import { useNavigate } from 'react-router-dom';

const PatentcardInsight = ({ patent, onClick, showBackgroundColor }) => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
  
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
  
    return (
      <div className="card-wrapperInsight" onClick={onClick} style={{ cursor: 'pointer' }}>
        <Card 
          style={{
            border: "1px solid #dbdbde",
            margin: "20px",
            width: "300px",
            borderRadius: "15px",
            position: 'relative',
            overflow: 'hidden',
            background: showBackgroundColor ? '   linear-gradient(135deg, #A6FFCB, #12D8FA)' : 'none' 
          }}  
          
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => moveToPatentPage(navigate, patent.publication_number, "about")}
        >
           <CardBody style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, position: "relative", top: "0px" }}>
            <CardTitle className="" style={{fontSize: "1rem", fontWeight: "bold", marginTop: "10px"}}>
              {truncateText(toTitleCase(patent.title), 80)}
            </CardTitle>
            {/* {console.log(patent.assignee?)} */}
            <h2 className="details-patent" style={{ flexShrink: 0, fontSize: "0.7rem", color: "#838c95", marginTop: "20px" }}>
              {patent.assignee
                    ? patent.assignee[0]
                      ? toTitleCase(patent.assignee[0])
                      : toTitleCase(patent.inventor[0])
                    : patent.name
                    ? toTitleCase(patent.name)
                    : toTitleCase(patent.inventor)} 
              | {toTitleCase(patent.industry)} 
              | {patent.publication_year || String(patent.publication_date).slice(0, 4)} 
              | {patent.assignee_country || patent.country}
            </h2>
            <p className="patentDescription" style={{ flexGrow: 1, fontSize: "0.8rem", marginTop: "20px", color: "black" }}>
              {(patent.abstract)?truncateText(patent.abstract || "", 180):truncateText(patent.abstract_text || "", 180)}
            </p>
          </CardBody>
        </Card>
      </div>
    );
  };
  
  export default PatentcardInsight;
