import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Row,
  Col,
} from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { SearchSection } from "../../components/searchSection/searchSection";
import { Logo } from "../../components/logo/logo";
import { Footer } from "../../components/footer/footer";
import "./landingPage.css";
import { Tracking } from "../../components/tracking/tracking";
import Customers from "../../components/ourCustomers/customer";
import SimpleMap from "../../components/WorldMap/WorldMap";
// import ExampleTable from '../../components/Tables/exampleTable';
// import CapabilityTable from '../../components/Tables/capabilityTable';
// import LimitationTable from '../../components/Tables/limitationTable';
import SampleQueries from "./sampleQueries.json";
import cachedResults from "../searchResults/cachedResults.json";
import { moveToResultsPage, moveToTechnologyPage, toTitleCase } from "../../actions/helper";
import PatentList from "../../components/patentsList/patentList";
import LineChart from "../../components/Charts/lineChart";
import DonutChart from "../../components/Charts/pieChart";
import { FaSearch } from "react-icons/fa";
import { FaUser, FaUnlock, FaLock } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";
import Partners from "../../components/partners/partners";
import SignupForm from "../../components/signupform/signupForm";

export const LandingPage = (props) => {
  const [query, setQuery] = useState();
  const [flag, setFlag] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [orgName, setOrgName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState("");
  const [emergingIndustries, setEmergingIndustries] = useState(
    Object.keys(cachedResults)
  );
  const [step, setStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationMode, setIsVerificationMode] = useState(false);
  const [recentSearches, setRecentSearches] = useState();
  const [keyPlayers, setKeyPlayers] = useState([
    { org: "SAMSUNG ELECTRONICS CO., LTD.", count: 193, previousCount: 222 },
    { org: "CANON KABUSHIKI KAISHA", count: 72, previousCount: 55 },
    {
      org: "INTERNATIONAL BUSINESS MACHINES CORPORATION",
      count: 71,
      previousCount: 64,
    },
    { org: "SAMSUNG DISPLAY CO., LTD.", count: 69, previousCount: 68 },
    { org: "QUALCOMM Incorporated", count: 67, previousCount: 95 },
    { org: "Dell Products L.P.", count: 51, previousCount: 33 },
    { org: "HUAWEI TECHNOLOGIES CO., LTD.", count: 50, previousCount: 67 },
    { org: "EMC IP Holding Company LLC", count: 46, previousCount: 25 },
    { org: "Monsanto Technology LLC", count: 41, previousCount: 24 },
    { org: "TOYOTA JIDOSHA KABUSHIKI KAISHA", count: 36, previousCount: 42 },
  ]);
  const [researchTitle, setResearchTitle] = useState("Neuroscience");

  // Table variables
  const [keyApplications] = useState([
    {
      publication_number: "20200272722",
      title: "Method and system for providing a brain computer interface",
      assignee: ["Arctop ltd."],
      industry: "Computational Technology",
      application_year: 2022,
      country: "USA",
      abstract:
        "A method for providing a brain computer interface that includes detecting a neural signal of a user in response to a calibration session having a time-locked component and a spontaneous component; generating...",
    },
    {
      publication_number: "20200133393",
      title: "Brain computer interface for augmented reality ",
      assignee: ["Cognixion"],
      industry: "Computational Technology",
      application_year: 2019,
      country: "US",
      abstract:
        "A method and system of a brain computer interface in a headset including an augmented reality display, one or more sensors, a processing module, at least one biofeedback device, and a battery. The interface...",
    },
  ]);

  const labels = [
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
  ];
  const seriesData = [52, 62, 55, 55, 74, 71, 72, 94, 106, 112, 87, 15];

  const donutChartValues1 = [35, 42, 20, 21, 26];
  const donutChartLabels1 = [
    "Measuring for diagnostic purposes",
    "Using electronic means",
    "Involving amino acids, proteins or peptides",
    "Characterised by a protocol",
    "Bus structure",
  ];

  const donutChartValues2 = [30, 70];
  const donutChartLabels2 = ["Granted", "Pending"];

  const additionalText = Object.values(cachedResults).map((item) => {
    return item.facets.assignee_country.length;
  });

  const additionalInfo = Object.values(cachedResults).map((item) => {
    return item.facets.assignee.slice(0, 2);
  });

  // Research Section Variables
  const [recentQuery, setRecentQuery] = useState(
    "Unmanned Supersonic jet aircraft"
  );
  const [summary, setSummary] = useState(
    "In 2010, Hypermach aerospace industries incorporated filed a patent on Supersonic aircraft with shockwave canceling aerodynamic configuration."
  );
  const [countryCount, setCountryCount] = useState(36);
  const [topAssignee, setTopAssignee] = useState("The Boeing Company");
  const [topYear, setTopYear] = useState(2019);
  const [topCountry, setTopCountry] = useState(36);
  const [mapData, setMapData] = useState([
    ["US", 531],
    ["JP", 70],
    ["CN", 69],
    ["DE", 57],
    ["FR", 41],
    ["KR", 25],
    ["GB", 24],
    ["CA", 19],
    ["TW", 10],
    ["AU", 6],
    ["RU", 5],
    ["SG", 5],
    ["IL", 5],
    ["CH", 4],
    ["ES", 4],
    ["SE", 4],
    ["BE", 3],
    ["BR", 2],
    ["NL", 2],
    ["CT", 2],
    ["FI", 2],
    ["PK", 1],
    ["VG", 1],
    ["NZ", 1],
    ["IT", 1],
    ["DK", 1],
    ["AT", 1],
    ["RO", 1],
    ["BY", 1],
    ["SA", 1],
    ["HR", 1],
    ["GR", 1],
    ["KY", 1],
    ["IE", 1],
    ["NO", 1],
    ["GD", 1],
  ]);

  // for customers
  const customers = [
    "The process is fast, efficient, cost effective and the results have been outstanding. Strategically we can do much more at a fraction of the cost and at incredible speed.",
    "I have been working with Incubig since the last two years, and they have never disappointed me. Reports are detailed and meet all the expectations. Their report on Covid19 in early 2020 was shared with the government and was well appreciated.",
    "Incubig's solution & platform is needed in South Korea. One of the top companies in KSGC 2019 competition, signed MoUs and joint venture agreements with leading corporations.",
  ];
  const customerName = [
    "Michael Wright, Founding Partner Intercepting Horizons",
    "Satish Mehta, Director Digital India Fund",
    "Chelsea Han, Manager NIPA",
  ];

  const statsData = [
    { number: 100, tag: "Users" },
    { number: 25, tag: "Projects" },
    { number: 50, tag: "Teams" },
  ];

  const emergingIndustriesURL =
    "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/cache/recent";
  const recentSearchesURL =
    "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/cache/recent";
  const keyPlayersURL =
    "https://09rypb92vg.execute-api.ap-south-1.amazonaws.com/alpha/api/v1/top-orgs";
  const emailURL =
    "https://client-communication.azurewebsites.net/api/client-communication";

  useEffect(() => {
    axios.get(recentSearchesURL).then((response) => {
      setRecentSearches(response.data);
    });
    axios.get(keyPlayersURL).then((response) => {
      setKeyPlayers(response.data.slice(0, 5));
    });
  }, []);

  const modifiedCustomerName = customerName.map((name) => (
    <span>
      {name.split(", ").map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index < name.split(", ").length - 1 && <br />}
        </React.Fragment>
      ))}
    </span>
  ));

  const sendEmail = () => {
    const recipients = {
      to: [{ email: "ketan@incubig.org", name: "Ketan Chandra" }],
    };
    const content = {
      subject: "(System Alert) DYR Full Access Requested",
      body: `${email} from ${orgName} has requested full access to the dashboard`,
      html: "",
    };
    axios.post(emailURL, { recipients: recipients, content: content });
  };

  const scrollToSection = () => {
    const targetSection = document.querySelector(".belowDontReactText");
    const isLoggedIn = localStorage.getItem("token") !== null;
  
    if (isLoggedIn) {
      window.location.href = "/for-you/report";
    } else {
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - 50,
          behavior: "smooth",
        });
      }
    }
  };

  const navigate = useNavigate();

  const containerRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const startScroll = () => {
      animationRef.current = requestAnimationFrame(scroll);
    };

    const stopScroll = () => {
      cancelAnimationFrame(animationRef.current);
    };

    const scroll = () => {
      if (container.scrollLeft <= 0) {
        // Reset scroll position to the end
        container.scrollTo({ left: container.scrollWidth, behavior: "auto" });
      } else {
        container.scrollLeft -= 1.5; // Adjust the scroll speed as desired
      }

      animationRef.current = requestAnimationFrame(scroll);
    };

    startScroll();

    container.addEventListener("mouseenter", stopScroll);
    container.addEventListener("mouseleave", startScroll);

    // Clean up the event listeners when the component unmounts
    return () => {
      cancelAnimationFrame(animationRef.current);
      container.removeEventListener("mouseenter", stopScroll);
      container.removeEventListener("mouseleave", startScroll);
    };
  }, []);

  const tableContainerRef = useRef(null);
  const tableAnimationRef = useRef(null);

  return (
    <div className="landingPage">
      <Tracking />

      <Row className="g-0">
        <PersonalizedHeader />
      </Row>
      <Row className="g-0">
        <SearchSection />
      </Row>

      <Row className="g-0">
        <Col>
          <div className="queryHeading">
            Get <strong className="dyrTextLanding">Intelligence</strong> on{" "}
            <strong className="dyrTextLanding">
              Patents, technology & industry
            </strong>
          </div>
        </Col>
      </Row>

      <hr className="belowQueryHeading" />

      <Row className="g-0" style={{ marginLeft: "30px", marginRight: "30px" }}>
        <div className="cardContainer" ref={containerRef}>
          <div className="cardWrapper">
            {emergingIndustries.map((item, index) => (
              <div className="cardEnclosed" key={index}>
                <div className="card">
                  <div className="cardBody">
                    <p className="cardText">
                      {additionalText[index]} countries working on
                    </p>
                    <h5 className="cardTitle">{item}</h5>
                    <p className="additionalInfo">
                      {toTitleCase(additionalInfo[index][0][0])}, and{" "}
                      {toTitleCase(additionalInfo[index][1][0])} active.
                    </p>
                    <button
                      onClick={() => {
                        moveToTechnologyPage(navigate,"overview",cachedResults[item]["cpc"]);
                      }}
                      key={index}
                      style={{ cursor: "pointer" }}
                      className="viewButton"
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      <Row className="g-0">
        <Col
          xs={{ span: 12, order: 1 }}
          md="5"
          style={{ backgroundColor: "#f0f0f0", color: "black" }}
        >
          <div className="landingSections">
            <div className="searchDiv">
              <div
                className="landingBrandname sectionBrandName"
                style={{ color: "black" }}
              >
                Navigate and
                <br />
                utilize
                <br />
                research data
                <br />
                with ease.
              </div>
              <div className="searchBarWrapper">
                <input
                  className="searchBar"
                  placeholder="brain computer interface"
                  style={{ width: "80%" }}
                />
                <FaSearch
                  className="searchIcon"
                  style={{ top: "0px", color: "black" }}
                  onClick={(e) => {
                    // moveToResultsPage(navigate, "brain computer interface");
                  }}
                />
              </div>
              <p className="landingPara brandNamePara">
                Easily discover state-of-the-art patents, breakthrough
                solutions, monitor trends, and accelerate your research, and
                product development.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="7">
          <div className="landingRightSections">
            {/* <div
              className="summaryTag"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginTop: "0px",
              }}
            >
              <span
                className="summaryTagText"
                style={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                Patent data
              </span>
            </div> */}
            <p
              className="para"
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              In 2020, Arctop ltd filed a patent on Method and system for
              providing a brain computer interface.
            </p>

            <PatentList keyApplications={keyApplications} />
          </div>

          <div className="landingForstats">
            <p
              style={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Research activity stats
            </p>
            <div className="box-container">
              <div className="box" style={{ borderRight: "1px solid black" }}>
                <div className="widget-block">
                  <div className="value">36</div>
                  <p className="landingBody3 landingStats">Countries</p>
                </div>
              </div>
              <div className="box" style={{ borderRight: "1px solid black" }}>
                <div className="widget-block">
                  <div className="value">IBM</div>
                  <p className="landingBody3 landingStats">Top organization</p>
                </div>
              </div>
              <div className="box">
                <div className="widget-block">
                  <div className="value">2238</div>
                  <p className="landingBody3 landingStats">
                    Number of inventors
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      <Row className="g-0">
        <Col
          xs={{ span: 12, order: 1 }}
          md="5"
          style={{ backgroundColor: "#020b26", color: "white" }}
        >
          <div className="landingSection2">
            <div className="searchDiv">
              <div className="landingBrandname sectionBrandName">
                Get Instant
                <br />
                Global
                <br />
                Technology
                <br />
                Insights
              </div>
              <div className="searchBarWrapper">
                <input
                  className="searchBar"
                  placeholder="neurological analysis using images"
                  style={{ width: "80%" }}
                />
                <FaSearch
                  className="searchIcon"
                  style={{ top: "0px", color: "black" }}
                  // onClick={(e) => {
                  //   moveToResultsPage(
                  //     navigate,
                  //     "neurological analysis using images"
                  //   );
                  // }}
                />
              </div>
              <p className="landingPara brandNamePara">
                With just a few clicks, know your technological future, stay
                informed about global research, and make right R&D and business
                decisions, at right time.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="7">
          <div className="landingRightSections">
            {/* <div
              className="summaryTag"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginTop: "0px",
              }}
            >
              <span
                className="summaryTagText"
                style={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                Technology
              </span>
            </div> */}

            <div className="landingTechnologyText">
              <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                Image Analysis
              </p>
              <p
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
              >
                20487 patents filed | 70 countries working | 5031 active
                organizations
              </p>
            </div>

            <div className="map">
              <SimpleMap
                data={mapData}
                color="blue"
                backgroundColor="white"
                borderColor="grey"
                label="distribution of organizations in different countries"
              />
            </div>
          </div>

          <div className="landingForstats">
            <div className="box-container">
              <div className="box" style={{ borderRight: "1px solid black" }}>
                <div className="widget-block">
                  <div className="value">Japan</div>
                  <p className="landingBody3 landingStats">Leading country</p>
                </div>
              </div>
              <div className="box" style={{ borderRight: "1px solid black" }}>
                <div className="widget-block">
                  <div className="value">Canon</div>
                  <p className="landingBody3 landingStats">Top organization</p>
                </div>
              </div>
              <div className="box">
                <div className="widget-block">
                  <div className="value">110</div>
                  <p className="landingBody3 landingStats">
                    Patents filed in 2020
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      <Row className="g-0">
        <Col
          xs={{ span: 12, order: 1 }}
          md="5"
          style={{ backgroundColor: "#f0f0f0", color: "black" }}
        >
          <div className="landingSection3">
            <div className="searchDiv">
              <div
                className="landingBrandname sectionBrandName"
                style={{ color: "black" }}
              >
                Never miss to
                <br />
                discover &
                <br />
                monitor Your
                <br />
                competition
              </div>
              <div className="searchBarWrapper">
                <input
                  className="searchBar"
                  placeholder="network chip for neurological data"
                  style={{ width: "80%" }}
                />
                <FaSearch
                  className="searchIcon"
                  style={{ top: "0px", color: "black" }}
                  // onClick={(e) => {
                  //   moveToResultsPage(
                  //     navigate,
                  //     "network chip for neurological data"
                  //   );
                  // }}
                />
              </div>
              <p className="landingPara brandNamePara">
                Easily monitor your competition, track their research, identify
                potential technology collaborators, and future acquisition
                prospects.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="7">
          <div className="landingRightSections">
            {/* <div
              className="summaryTag"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginTop: "0px",
              }}
            >
              <span
                className="summaryTagText"
                style={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                Organization
              </span>
            </div> */}

            <div className="landingTechnologyText">
              <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                Neuralink corp.
              </p>
              <p
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
              >
                Neuralink corp. is primarily researching on Implanted circuitry,
                followed by Information transfer, eg. neurological data.
              </p>
            </div>

            <div className="landingResearchAreaChart">
              <LineChart
                labels={labels}
                series={seriesData}
                axisLabel="Patents filed"
                title="YoY patent filing trends"
              />
            </div>

            <div className="landingDonuts">
              <DonutChart
                values={donutChartValues1}
                labels={donutChartLabels1}
                width="400px"
                height="400px"
              />

              <DonutChart
                values={donutChartValues2}
                labels={donutChartLabels2}
                width="250px"
                height="250px"
              />
            </div>
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="7">
          <div className="landingReact">
            <span
              className="summaryTagText"
              style={{ fontSize: "1rem", color: "black" }}
            >
              Expert level Patent Research & business Solutions
            </span>
          </div>
          <p className="landingReactText">
            Our
            <b className="landingReactText" style={{ marginLeft: "auto" }}>
              {" "}
              global team
            </b>{" "}
            of
            <b className="landingReactText" style={{ marginLeft: "auto" }}>
              {" "}
              IP experts, Data scientists, & Strategist,
            </b>{" "}
            is delivering
            <b className="landingReactText" style={{ marginLeft: "auto" }}>
              {" "}
              precise, & high value reports
            </b>{" "}
            for our clients.
          </p>
          <div className="landingAccess" onClick={scrollToSection}>
            <div className="landingAccessText">Request Reports</div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: 1 }} md="5">
          <div className="clientContainer">
            <div className="client1">
              <img
                src={process.env.PUBLIC_URL + "/incubig_michael.jfif"}
                className="rounded-circle"
                style={{ width: "25%", height: "25%" }}
                alt="My Image"
              />
              <div className="clientName">Michael Wright</div>
              <div className="clientTitle">
                Founding Partner, Intercepting Horizons
              </div>
              <div className="clientReview">
                The process is fast, efficient, cost effective and the results
                have been outstanding. Strategically we can do much more at a
                fraction of the cost and at incredible speed.
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="g-0">
        <Col xs="12">
          <p className="paraReport">Get Our Reports</p>
          <hr className="landingLinePortfolio" />
          <div className="list-container">
            <ul className="list">
              <p
                className="para"
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  fontSize: "1.3rem",
                  marginLeft: "0px",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                Own your invention
              </p>
              <li>Patent Novelty analysis.</li>
              <li>Claim recommendations.</li>
              <li>Increased valuation.</li>
              <div className="getReport" onClick={scrollToSection}>
                <div className="getReportText">Get Report</div>
              </div>
            </ul>
            <span className="separator"></span>

            <ul className="list">
              <p
                className="para"
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  fontSize: "1.3rem",
                  marginLeft: "0px",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                Freedom-to-operate
              </p>
              <li>Product infringement analysis.</li>
              <li>Workaround strategies.</li>
              <li>Product geo-positioning</li>
              <div className="getReport" onClick={scrollToSection}>
                <div className="getReportText">Get Report</div>
              </div>
            </ul>
            <span className="separator"></span>
            <ul className="list">
              <p
                className="para"
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  fontSize: "1.3rem",
                  marginLeft: "0px",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                Know Your Technology
              </p>
              <li>Technology gaps analysis.</li>
              <li>Right R&D strategies.</li>
              <li>Technology advancement & growth.</li>
              <div className="getReport" onClick={scrollToSection}>
                <div className="getReportText">Get Report</div>
              </div>
            </ul>
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      <Row className="g-0" style={{ marginLeft: "30px", marginRight: "20px" }}>
        <Col xs="12" md="12">
          <Partners isMoving={true} />
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      {(!localStorage.getItem('token'))&&
      <div className="landingSignupPage">
        <Row className="g-0">
          <Col
            xs="12"
            className="formLeft d-flex justify-content-center flex-column"
          >
            <p className="dontReactText">
              Dont
              <br />
              react to change,
              <br />
              know future landscape.
            </p>
            <div
              className=" belowDontReact"
              style={{
                width: "auto",
                backgroundColor: "transparent",
                color: "white",
                marginTop: "20px",
              }}
            >
              <span className="summaryTagText belowDontReactText">
                Fuel Your R&D and Business with personalised Research
                Intelligence.
              </span>
            </div>
              <SignupForm/>
            <hr className="hrLine" />
          </Col>
        </Row>
      </div>
      }
      
      <Row className="g-0">
        <Footer />
      </Row>
    </div>
  );
};
