import React, { useState }  from 'react';
import { Container,Button,
    Form,
    FormGroup,
    Label,
    Input,Row } from "reactstrap";
    import axios from "axios";
import { FaUser, FaUnlock, FaLock } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import "../../pages/signUp/signUp.css";
import { moveToResultsPage, moveToSignInPage, truncateText } from '../../actions/helper';
import { useNavigate } from 'react-router-dom';

const SignupForm = () => {
  const [flag, setFlag] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [orgName, setOrgName] = useState("self");
  const [type, setType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationMode, setIsVerificationMode] = useState(false);
  const navigate = useNavigate();

    const emailURL =
    "https://client-communication.azurewebsites.net/api/client-communication";
  const registerURL =
    "https://api.incubig.org/authorization/register"

    const sendEmail = () => {
        const recipients = {
          to: [{ email: "ketan@incubig.org", name: "Ketan Chandra" }],
        };
        const content = {
          subject: "(System Alert) DYR Full Access Requested",
          body: `${email} from ${orgName} has requested full access to the dashboard`,
          html: "",
        };
        axios.post(emailURL, { recipients: recipients, content: content });
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage("");
      
        // if (email.endsWith('@gmail.com')) {
        //   setErrorMessage("Sign up not allowed for Gmail addresses.");
        //   return;
        // }
      
        let payload = { email: email, password: password, name: name, employer: "self" };
      
        axios.post(registerURL, payload).then((res) => {
          if (res.data.status_code === 200) {
            setFlag(false);
      
            localStorage.setItem("user", name);
            localStorage.setItem("email", email);
            localStorage.setItem("token", res.data.msg);
            sendEmail();
            navigate('/for-you/state-of-the-art');
          } else {
            setErrorMessage(truncateText(res.data.msg, 50));
            sendEmail();
          }
      
          setStep(4);
        });
      };
      
    
      const handleVerificationSubmit = () => {
        setStep(2);
      };
    
      const handleSubmitCode = (e) => {
        e.preventDefault();
        setStep(3);
      }
    
      const handleVerified = (e) => {
        e.preventDefault();
        setStep(4);
      }

      const moveToSignIn = () => {
        navigate("/sign-in");
      };

  return (
    <div>
        <Row className="g-0">
        <div className="access">
            <div className="accessText">Get Started Now!</div>
          </div>
          <Container className="d-flex justify-content-center signupContainer">
            {flag ? (
              <div style={{ width: "95%" }}>
                <div className="getAccessText">Get Access.</div>
                <Form
                  style={{ width: "100%", marginTop: "50px" }}
                  onSubmit={isVerificationMode ? handleVerificationSubmit : handleSubmit}
                >
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="name"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <FaUser />
                      &nbsp;&nbsp;Name
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="email"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <MdMail />
                      &nbsp;&nbsp;Email
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter your academic/business email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="name"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <FaUnlock />
                      &nbsp;&nbsp;Password
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="type"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <FaLock />
                      &nbsp;&nbsp;Confirm Password
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="confirm-password"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Confirm password"
                      onChange={(e) => setType(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <a className="leftSignUp" onClick={moveToSignIn}> Registered? Sign in.
                  </a>

                  {isVerificationMode && (
              <FormGroup className="mt-4 d-flex align-items-center" style={{ marginBottom: "100px" }}>
                <Label for="verificationCode" style={{ marginRight: "0px", fontWeight: "bold", marginBottom: "5px", width: "50%" }}>
                  Verification Code
                </Label>
                <Input
                  type="text"
                  name="verificationCode"
                  id="verificationCode"
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "#000",
                    fontSize: "0.875rem",
                    marginBottom: "5px",
                    width: "70%",
                  }}
                  placeholder="Enter verification code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  required
                />
              </FormGroup>
            )}

                  <p className="mt-4" style={{color:'red'}}>{errorMessage}</p>
                  <Button type="submit" className="signupButton">
              {isVerificationMode ? 'Verify' : 'Submit'}
            </Button>
                </Form>
              </div>
            ) : (
              <div className="afterSignUp">
                <p className="mt-4">
                  We have received your request, we will get back to you with
                  updates.{" "}
                </p>
                <Button
                  className="signupDYR"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    moveToResultsPage(navigate, "Cold nuclear fusion");
                  }}
                >
                  Do Your Research
                </Button>
              </div>
            )}
          </Container>

{/* <Container className="d-flex justify-content-center signupContainer">
 {step ===1 && (
              <div style={{ width: "95%" }}>
                <div className="getAccessText">Get Access.</div>
                <Form
                  style={{ width: "100%", marginTop: "50px" }}
                  onSubmit={handleSubmit}
                >
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="name"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <FaUser />
                      &nbsp;&nbsp;Name
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="email"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <MdMail />
                      &nbsp;&nbsp;Email
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter your academic/business email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="name"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <FaUnlock />
                      &nbsp;&nbsp;Password
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="type"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <FaLock />
                      &nbsp;&nbsp;Confirm Password
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="confirm-password"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Confirm password"
                      onChange={(e) => setType(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <p className="mt-4" style={{color:'red'}}>{errorMessage}</p>
          <Button type="submit" className="signupButton" onClick={()=>{()=>{handleSubmit()}}}>
            Submit
          </Button>
        </Form>
      </div>
                  )}

                  {step === 2 && (
            <Form style={{ width: "90%", marginTop: "50px" }} onSubmit={handleSubmitCode}>
              <FormGroup className="mt-4 d-flex align-items-center" style={{ marginBottom: "100px" }}>
                <Label for="verificationCode" style={{ marginRight: "0px", fontWeight: "bold", marginBottom: "5px", width: "50%" }}>
                  Enter email verification code
                </Label>
                <Input
                  type="text"
                  name="verificationCode"
                  id="verificationCode"
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "#000",
                    fontSize: "0.875rem",
                    marginBottom: "5px",
                    width: "70%",
                  }}
                  placeholder="Enter email verification code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  required
                />
              </FormGroup>
              <p className="mt-4" style={{color:'red'}}>{errorMessage}</p>
          <Button type="submit" className="signupButton">
            Submit
          </Button>
        </Form>
            )}

            {step === 3 && (
                <div className="afterSignUp">
                <p className="mt-4">
                  Successfully signed up.{" "}
                </p>
                <Button
                  className="signinDYR"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    moveToResultsPage(navigate, "Cold nuclear fusion");
                  }}
                >
                  Do Your Research
                </Button>
              </div>
            )}   
          </Container> */}
        </Row>
    </div>
  )
}

export default SignupForm;