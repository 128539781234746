import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { moveToCompanyPage, toTitleCase } from "../../actions/helper";
import "./grid.css";

const GridPatent = (props) => {
  const navigate = useNavigate();

  const redirectToAssignee = () => {
    if (
      props.assignees &&
      props.assignees.length > 0 &&
      props.inventors &&
      props.inventors.length > 0
    ) {
      if (props.assignees[0] === props.inventors[0]) {
        console.log("Inventor is the same as assignee");
      } else {
        moveToCompanyPage(navigate, props.assignees[0]);
      }
    } else if (props.assignees && props.assignees.length > 0) {
      moveToCompanyPage(navigate, props.assignees[0]);
    }
  };
  
  return (
    <div className="grid-container" style={{ marginRight: "20px" }}>
      <div
        className="heading"
        style={{
          backgroundColor: "#020b26",
          width: "100%",
          padding: "10px",
          color: "white",
          display: "flex",
          alignItems: "flex-start",
          marginLeft: "0px",
          justifyContent: "center",
        }}
      >
        <span style={{ fontSize: "0.8rem" }}>{props.gridHeading}</span>
      </div>
      {props.showPublication && (
        <div className="upper-grid">
          <div className="grid-item">
            {props.publicationNumber ? (
              <h2>{props.publicationNumber}</h2>
            ) : (
              <Skeleton />
            )}
            <p>Publication Number</p>
          </div>
        </div>
      )}
      <div className="upper-grid">
        <div className="grid-item">
          {props.applicationDate ? (
            <h2>{props.applicationDate}</h2>
          ) : (
            <Skeleton />
          )}
          <p>Filed</p>
        </div>
        <div className="grid-item">
          {props.publicationDate ? (
            <h2>{props.publicationDate}</h2>
          ) : (
            <Skeleton />
          )}
          <p>Published</p>
        </div>
      </div>

      <div className="lower-grid">
        <div className="grid-item">
          {props.status ? <h2>{props.status}</h2> : <Skeleton />}
          <p>Legal Status</p>
        </div>
        <div className="grid-item">
          {props.industry ? (
            <h2>{toTitleCase(props.industry)}</h2>
          ) : (
            <Skeleton />
          )}
          <p>Industry</p>
        </div>
      </div>

      <div className="lower-grid">
        {props.grantDate && (
          <>
            <div className="grid-item">
              <h2>{props.grantDate}</h2>
              <p>Grant Date</p>
            </div>
            <div className="grid-item">
              <h2>{props.expiryDate}</h2>
              <p>Expiration Date</p>
            </div>
          </>
        )}
      </div>

      <div className="lower-grid">
        <div className="grid-item" style={{height: "auto"}}>
          {props.assignees && props.assignees.length > 0 ? (
            <h2
            style={{
              cursor:
                props.assignees && props.assignees[0] === props.inventors[0]
                  ? "disabled"
                  : "pointer",
              textDecoration:
                props.assignees && props.assignees[0] === props.inventors[0]
                  ? "none"
                  : "underline",
            }}
            onClick={() => {
              redirectToAssignee();
            }}
          >
            {toTitleCase(props.assignees[0])}
          </h2>
          
          ) : props.inventors && props.inventors.length > 0 ?  (
            <h2>{toTitleCase(props.inventors[0])}</h2>
          ) : (
            <Skeleton />
          )}
          <p>Primary Assignee</p>
        </div>

        <div className="grid-item" style={{height: "auto"}}>
          {props.inventors ? (
            <h2>
              {props.inventors && props.inventors.length > 0 ? (
                toTitleCase(props.inventors[0])
              ) : (
                <Skeleton />
              )}
            </h2>
          ) : (
            <Skeleton />
          )}
          <p>First Inventor</p>
        </div>
      </div>
    </div>
  );
};

export default GridPatent;
