// import React, { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
// import Skeleton from "react-loading-skeleton";
// import "./table.css";

// const DonutChart = (props) => {
//   const colors = ["#5C92AC", "#336F8C", "#0B4C6C", "#00334D", "#001A2E"];
//   const [values, setValues] = useState(props.values);

//   useEffect(() => {
//     setValues(props.values);
//   }, [props.values]);

//   // Options for the chart
//   const chartOptions = {
//     labels: props.labels.map((label, index) => `${label}: ${values[index]}`),
//     colors: colors,
//     dataLabels: {
//       enabled: false,
//     },
//     legend: {
//       show: props.hideLegend ? false : true,
//       position: props.legendPosition || "top",
//       markers: {
//         fillColors: colors,
//       },
//     },

//     tooltip: {
//       custom: function ({ series, seriesIndex, w }) {
//         let textColor = seriesIndex === 1 ? "#000000" : "#FFFFFF";

//         let fontSize = "0.875rem"; 

//         // Setting suffix
//         let suffix =
//           series.reduce(function (a, v) {
//             return a + v;
//           }, 0) === 100
//             ? "%"y
//             : "";
//         return `
//           <div class="arrow_box" style="max-width: 450px; word-wrap: break-word; white-space: normal;">
//             <span style="color: ${textColor}; font-size: ${fontSize};">
//               ${w.config.labels[seriesIndex] + suffix}
//             </span>
//           </div>
//         `;
//       },
//     },
//     plotOptions: {
//       pie: {
//         expandOnClick: false,
//         enabled: true,
//         donut: {
//           size: 50,
//         },
//       },
//     },
//     type: "pie",
//   };

//   return (
//     <div
//     className={`pie-chart-container ${props.className}`}
//       style={{ width: props.width, height: props.height }}
//     >
//       <div className="chart">
//         {values && values.length > 0 ? (
//           <ReactApexChart options={chartOptions} series={values} type="pie" />
//         ) : (
//           <Skeleton circle={true} width={"50%"} height={250} />
//         )}
//       </div>
//       <div
//         className="chart-label"
//         style={{ textAlign: "center", fontSize: "0.8rem" }}
//       >
//         {props.label}
//       </div>
//     </div>
//   );
// };

// export default DonutChart;




import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import "./table.css";

const PieChart = (props) => {
  const { labels, hideLegend, legendPosition } = props;
  const colors = ["#5C92AC", "#336F8C", "#0B4C6C", "#00334D", "#001A2E"];
  // const colors = ["#3498db", "#55acee", "#007bb5", "#158cba", "#00a3e0"];
  const [values, setValues] = useState(props.values);

  useEffect(() => {
        setValues(props.values);
      }, [props.values]);

  const chartOptions = {
    labels: labels,
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: hideLegend ? false : true,
      position: legendPosition || "top",
      markers: {
        fillColors: colors,
      },
      fontSize: "16px",
      itemMargin: {
        vertical: 5, 
      },
    },

    tooltip: {
      fontSize: "12px",
    },

    plotOptions: {
      pie: {
        expandOnClick: false,
        enabled: true,
        donut: {
          size: 50,
        },
      },
    },
    type: "pie",
  };

  const chartSeries = values;

  return (
        <div
        className={`pie-chart-container ${props.className}`}
          style={{ width: props.width, height: props.height }}
        >
          <div className="chart">
            {values && values.length > 0 ? (
              <Chart options={chartOptions} series={values} type="pie" />
            ) : (
              <Skeleton circle={true} width={"50%"} height={250} />
            )}
          </div>
          <div
            className="chart-label"
            style={{ textAlign: "center", fontSize: "0.8rem" }}
          >
            {props.label}
          </div>
        </div>
      );
    };

export default PieChart;
