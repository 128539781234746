import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import PatentList from "../../components/patentsList/patentList";
import Grid from "../../components/Grid/grid";
import { createFilters, sanitizeText } from "../../actions/helper";
import ISOCode from "../../actions/countryISOcodes.json";
import { Tracking } from "../../components/tracking/tracking";

const DataSet = (props) => {
  // const searchStatus = props.searchStatus
  const keyApplications = props.keyApplications;
  const keyGeographies = props.keyGeographies
    ? [["All countries"]].concat(props.keyGeographies)
    : ["All countries"];
  const [selectedSortingLogic, setSelectedSortingLogic] = useState("Relevant");
  const [selectedCountry, setSelectedCountry] = useState(
    props.keyGeographies && props.keyGeographies.length === 1
      ? props.keyGeographies[0][0]
      : "All countries"
  );
  const [selectedCity, setSelectedCity] = useState(
    props.keyCities && props.keyCities.length === 1
      ? props.keyCities[0][0]
      : "All cities"
  );
  const keyCities = props.keyCities
    ? [["All cities"]].concat(props.keyCities)
    : ["All cities"];

  const handleButtonClick = (option) => {};

  const handleSortingLogicChange = (e) => {
    // console.log(e.target.value)
    setSelectedCountry("All countries");
    if (e.target.value === "Relevant") {
      props.searchFunction(props.query, null, false);
    } else {
      props.searchFunction(props.query, null, true);
    }
    setSelectedSortingLogic(e.target.value);
  };

  const handleCountryChange = (event) => {
    // console.log(window.location.search.split("?q=")[1])
    setSelectedSortingLogic("Relevant");

    let filter = null;
    if (event.target.value === "All countries") {
      props.searchFunction(
        decodeURIComponent(window.location.search).split("?q=")[1],
        filter
      );
    } else {
      filter = createFilters("country", event.target.value);
      props.searchFunction(
        decodeURIComponent(window.location.search).split("?q=")[1],
        filter
      );
    }
    setSelectedCountry(event.target.value);
  };

  return (
    <div>
      <Tracking
        title={"Research overview for :" + props.query}
        description={"Patents in " + props.query}
        keywords={props.query}
      />
      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="8" className="borderRight">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Patent data
            </span>
          </div>
          <div
            className="para&country"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {keyApplications ? (
              <p className="para" style={{ marginTop: "10px" }}>
                In{" "}
                {keyApplications[0]["application_date"].toString().slice(0, 4)},{" "}
                {keyApplications[0].assignee[0]
                  ? sanitizeText(keyApplications[0].assignee[0])
                  : sanitizeText(keyApplications[0].inventor[0])}{" "}
                filed a patent on {sanitizeText(keyApplications[0].title)}.
              </p>
            ) : (
              <p className="para" style={{ marginTop: "10px" }}>
                <Skeleton />
              </p>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <div
              className="headline-3 d-flex "
              style={{
                fontSize: "0.7rem",
                marginLeft: "25px",
                marginTop: "30px",
              }}
            >
              Click to read patents
            </div>

            <div className="patentAction">
              <div onClick={handleButtonClick}>
                <select
                  value={selectedSortingLogic}
                  onChange={handleSortingLogicChange}
                  style={{
                    fontSize: "0.7rem",
                    height: "26px",
                    marginRight: "10px",
                  }}
                >
                  {["Relevant", "Recent"].map((sortingLogic) => (
                    <option
                      key={sortingLogic}
                      value={sortingLogic}
                      style={{ fontSize: "0.7rem" }}
                    >
                      {sortingLogic}
                    </option>
                  ))}
                </select>
              </div>
              <div onClick={() => handleButtonClick("Country")}>
                <select
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  style={{
                    fontSize: "0.7rem",
                    height: "26px",
                    marginRight: "10px",
                  }}
                >
                  {keyGeographies.map((country) => (
                    <option
                      key={country[0]}
                      value={country[0]}
                      style={{ fontSize: "0.7rem" }}
                    >
                      {country[0] !== "All countries"
                        ? ISOCode[country[0]]
                        : country[0]}
                    </option>
                  ))}
                </select>
              </div>
              {props.keyCities && (
                <div onClick={() => handleButtonClick("Country")}>
                  <select
                    value={selectedCity}
                    onChange={handleCountryChange}
                    style={{
                      fontSize: "0.7rem",
                      height: "26px",
                      marginRight: "10px",
                    }}
                  >
                    {keyCities.map((city) => (
                      <option
                        key={city[0]}
                        value={city[0]}
                        style={{ fontSize: "0.7rem" }}
                      >
                        {city[0] !== "All cities" ? city[0] : city[0]}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
          <PatentList
            keyApplications={keyApplications}
            downloadOption={{
              show: true,
              params: { query: localStorage.getItem("query") },
            }}
          />
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="4">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Query Summary{" "}
              {selectedCountry != "All countries"
                ? " in " + ISOCode[selectedCountry] + " "
                : ""}{" "}
            </span>
          </div>
          <div className="forStats">
            <p>Summary of recent 1000 patents</p>
            <Grid
              gridHeading={
                "Summary" +
                (selectedCountry != "All countries"
                  ? " (" + ISOCode[selectedCountry] + ")"
                  : "")
              }
              upperGrid={[
                {
                  value:
                    props.keyGeographies && props.keyGeographies.length > 0
                      ? props.keyGeographies.length
                      : undefined,
                  caption: "Countries working",
                },
                {
                  value:
                    props.keyPlayers && props.keyPlayers.length > 0
                      ? props.keyPlayers.length
                      : undefined,
                  caption: "Active organizations",
                },
                {
                  value:
                    props.keyInventors && props.keyInventors.length > 0
                      ? props.keyInventors.length
                      : undefined,
                  caption: "Active inventors",
                },
              ]}
              lowerGrid={[
                {
                  value:
                    props.keyGeographies && props.keyGeographies.length > 0
                      ? props.keyGeographies[0][0]
                      : undefined,
                  caption: "Leading country",
                },
                {
                  value: props.topYearContribution,
                  caption: "Patents in " + props.topYear,
                },
                { value: props.grantPercent, caption: "Patents Granted" },
              ]}
              topPlayer={
                props.keyPlayers && props.keyPlayers.length > 0
                  ? props.keyPlayers[0][0]
                  : undefined
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DataSet;
