import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Table } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import SimpleMap from '../../components/WorldMap/WorldMap';
import { createFilters, moveToCompanyPage, removeDuplicates, sanitizeText } from "../../actions/helper";
import { Carousel } from "../../components/carousel/carousel";
import axios from 'axios'
import ISOCode from '../../actions/countryISOcodes.json'
import { Tracking } from "../../components/tracking/tracking";
import { useNavigate } from "react-router-dom";
import HorizontalBarChart from "../../components/horizontalBarChart/horizontalBarChart";

const Global = (props) => {
  const navigate = useNavigate()
  const [insights, setInsights] = useState();
  const [searchStatus, setSearchStatus] = useState(false);
  const [keyPlayers, setKeyPlayers] = useState([]);
  const [keyInventors, setKeyInventors] = useState("1000");
  const [keyGeographies, setKeyGeographies] = useState([]);
  const [topCountryInfo, setTopCountryInfo] = useState();

  useEffect(()=>{
    setInsights(props.insights)
    setSearchStatus(props.searchStatus)
    setKeyPlayers(props.keyPlayers)
    setKeyInventors(props.keyInventors)
    setTopCountryInfo(props.topCountryInfo)
    setKeyGeographies(props.keyGeographies)
  },[props])

  const searchURL =
    "https://hiykgsfdzd.execute-api.us-east-1.amazonaws.com/production/xsearch";


  const getTopCountryInfo = (country, maxCount = 5) => {
      setTopCountryInfo();
      axios
        .get(searchURL, {
          params: {
            query: props.query,
            filter: createFilters("assignee_country", country),
          },
        })
        .then((response) => {
          // response.data.facets.assignee.forEach((item)=>{
  
          // })
          setTopCountryInfo({
            country: country,
            assignees: removeDuplicates(
              response.data.facets.assignee.slice(0, maxCount)
            ),
            inventors: response.data.facets.inventors,
          });
        });
  };


  return (
    <div>
      <Tracking title={"Research overview for :"+props.query} description={"Patents in "+props.query} keywords={props.query}/>
      <Row className="g-0">
        <Col xs="12" md="6" className="borderRight">
          <div
              className="summaryTag"
              style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "15px", height: "auto"}}
            >
              <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
                Global activity summary
              </span>
          </div>
          {typeof insights != "undefined" && (
            <div style={{marginTop: "10px"}}>{insights}</div>
          )}
          
        </Col>

        <Col xs="12" md="6">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px"
            }}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem"}}>Country Stats</span>
          </div>
          <div className="box-container">
            <div className="box" style={{ borderRight: "1px solid black" }}>
              <div className="widget-block">
                {typeof keyGeographies !== "undefined" ? (
                  <div className="value">
                    {keyGeographies.length}
                  </div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Countries
                </p>
              </div>
            </div>
            <div className="box" style={{ borderRight: "1px solid black" }}>
              <div className="widget-block">
                {typeof keyPlayers !== "undefined" ? (
                  <div
                    className="value"
                  >
                    {keyPlayers.length}
                  </div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Organizations
                </p>
              </div>
            </div>
            <div className="box">
              <div className="widget-block">
                {typeof keyInventors != "undefined" ? (
                  // <div className="value">{keyInventors}</div>
                  <div className="value">{keyInventors.length}</div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Inventors
                </p>
              </div>
            </div>
          </div>

          <div className="horizontalBarChart">
            <HorizontalBarChart keyGeographies={(keyGeographies&&keyGeographies.length>4)?keyGeographies.filter(item=>item[0]).slice(0,5):undefined} heading="Top five countries,based on number of organizations"/>
          </div>

        </Col>

        <Col xs="12" md="6" className="borderRight">
          <div
              className="summaryTag"
              style={{ width: "auto", backgroundColor: "white", color: "black", justifyContent: "center" }}
            >
              <span className="summaryTagText" style={{fontSize: "0.8rem"}}>
                Click country to see activity
              </span>
          </div>

          {/* <p className="para">
          <b className="globalStrong">{sanitizeText(topAssignee)}</b> has filed a total of  <b className="globalStrong">{topAssigneePatentCount}</b> patents in <b className="globalStrong">{ISOCode[firstCountry]}</b>, while in  <b className="globalStrong">{ISOCode[secondCountry]}</b>, <b className="globalStrong">{sanitizeText(assignee)}</b> leads theresearch efforts.
          </p> */}

          <div className="citationMap">
            <SimpleMap
              data={keyGeographies}
              color="blue"
              backgroundColor="white"
              borderColor="grey"
              clickHandler={getTopCountryInfo}
            />
          </div>
        </Col>
        
        <Col xs="12" md="6">
          <h1 className="textActivity">Activity in  {topCountryInfo
                  ? ISOCode[topCountryInfo.country]
                  : "...loading..."}</h1>
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "30px",
            }}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem"}}>
              Top five active organisations
            </span>
          </div>

<Table className="mainTable globalTable">
        <thead>
          <tr>
            <th className="text-center" style={{fontWeight: "normal", marginLeft: "-2%", backgroundColor: "#020b26", color: "white"}}>Organization</th>


            <th className="text-center" style={{fontWeight: "normal", backgroundColor: "#020b26", color: "white"}}>
              Recent Patents</th>
          </tr>
        </thead>
        {typeof topCountryInfo != "undefined" ? (
        <tbody>
            {topCountryInfo.assignees.slice(0, 5).map((row, index) => (
              <tr className="rowTable"  key={index}
              onClick={() => {moveToCompanyPage(navigate,row[0])}}>
                <td className="tableFirstColumn">
                {sanitizeText(row[0])}
                </td>
                <td className="tableSecondColumn">
                {row[1]}
                </td>
              </tr>
            ))}
        </tbody>
          ) : (
            <tbody>
            <tr className="rowTable">
              <td className="tableFirstColumn">
                <Skeleton width={200} />
              </td>
              <td className="tableSecondColumn">
                <Skeleton width={150} />
              </td>
            </tr>
            </tbody>
          )}
      </Table>


        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      <Row className="g-0" style={{ height: "250px" }}>
        <Col md="12">
        <div
            className="summaryTag"
            style={{ width: "auto", backgroundColor: "white", color: "black" }}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
              See other active organizations
            </span>
          </div>
          <div className="cpcTable" style={{marginLeft: "0px", marginTop: "0px"}}>
            {searchStatus ? (
              <Carousel data={keyPlayers.slice(0, 10)} isMoving={true} />
            ) : (
              <Skeleton />
            )}
          </div>
        </Col>
      </Row>

    </div>
  )
}

export default Global;