import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { moveToPatentPage, sanitizeText, trackUsage } from "../../actions/helper";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FcLikePlaceholder } from "react-icons/fc";
import { FcLike } from "react-icons/fc";
import { MdShare } from "react-icons/md";
import "./patentViewHeader.css";

const PatentViewHeader = (props) => {
  const publicationNumber = decodeURIComponent(
    window.location.pathname.split("/").at(-1)
  );
  const patentTitle = props.title;
  const inventor = props.inventor;
  const industry = props.industry;
  const applicationDate = props.applicationDate;
  const navigate = useNavigate();

  const [loginPopoverShow, setLoginPopoverShow] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const isLoggedIn = localStorage.getItem("token") !== null;

  const loginPopover = (
    <Popover id="login-popover">
      <Popover className="popoverBody">Please log in to like.</Popover>
    </Popover>
  );

  const handleLike = () => {
    if (isLoggedIn) {
      trackUsage(
        "patent",
        { publication_number: publicationNumber, liked: true },
        props.cpc
      );
      setIsLiked(!isLiked);
    } else {
      setLoginPopoverShow(true);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: "Check out this document:",
          url: window.location.href,
        })
        .then(() => console.log("Successfully shared"))
        .catch((error) => console.error("Error sharing", error));
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div>
      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="6">
        <div className="technologyMonitor">
  <h2 className="patentdetails-title" style={{ marginTop: "0px", marginBottom: "10px" }}>
    {/* {publicationNumber} */}
  </h2>
  <div>
    {patentTitle ? (
      <h1 style={{ fontSize: "1.3rem", marginLeft: "11px" }}>
        {sanitizeText(patentTitle)}
      </h1>
    ) : (
      <h1>{props.flag ? <Skeleton /> : <p></p>}</h1>
    )}
  </div>
  <div style={{ display: "flex", alignItems: "center" }}>
    {applicationDate && (
      <h2 className="patentdetails-title">
        {inventor} | {industry} | {applicationDate}
      </h2>
    )}
    {/* Like button */}
    <div className="likeButton" title="Like this patent" onClick={handleLike} style={{ marginLeft: "10px" }}>
      {isLiked ? (
        <FcLike size={25} />
      ) : (
        <FcLikePlaceholder size={25} />
      )}
      {loginPopoverShow && (
        <Popover id="login-popover" className="popText">
          <div className="popText2">Please log in to like.</div>
        </Popover>
      )}
    </div>
    {/* Share button */}
    <div className="shareButton" title="Share" onClick={handleShare} style={{ marginLeft: "10px" }}>
      <MdShare size={22} />
    </div>
  </div>
</div>

        </Col>
        <Col xs={{ span: 12, order: 2 }} md="6" style={{ display: "flex" }}>
          <div className="sectionContainer">
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-2) == "about"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToPatentPage(navigate, publicationNumber, "about")
              }
            >
              Abstract
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-2) == "claims"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToPatentPage(navigate, publicationNumber, "claims")
              }
            >
              Claim
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-2) == "technology"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToPatentPage(navigate, publicationNumber, "technology")
              }
            >
              Technology
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-2) == "similar"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToPatentPage(navigate, publicationNumber, "similar")
              }
            >
              Similar
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PatentViewHeader;
