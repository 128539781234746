import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./searchResults.css";

import "../../styles/fontsizes_searchresult/fontSize.css";

import { Footer } from "../../components/footer/footer";
import axios from "axios";
import cachedResults from "../searchResults/cachedResults.json";
import ISOCode from "../../actions/countryISOcodes.json";
import base64 from "react-native-base64";
import { Tracking } from "../../components/tracking/tracking";
import "react-loading-skeleton/dist/skeleton.css";
import DataSet from "../../sections/searchResultsSummary/dataSet";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";
import { SearchPageHeader } from "../../sections/searchPageHeader/searchPageHeader";
import {
  createFilters,
  getParamsFromFilter,
  removeDuplicatePatents,
  removeDuplicates,
  sanitizeText,
  saveUserPreference,
  toTitleCase,
  trackUsage,
} from "../../actions/helper";
import Trends from "../../sections/searchResultsTrends/trends";
import Global from "../../sections/searchResultsGlobal/global";
import { SearchResultsTechnologies } from "../../sections/searchResultsTechnologies/searchResultTechnologies";

const SearchResult = (props) => {
  const location = useLocation();
  const cutoff = 0;

  let query = decodeURIComponent(location.search).split("q=").at(-1);
  const [searchStatus, setSearchStatus] = useState(false);
  const [applicationCount, setApplicationCount] = useState();
  const [keyApplications, setKeyApplications] = useState();
  const [trends, setTrends] = useState();
  const [maximaMinima, setMaximaMinima] = useState();
  const [researchYear, setResearchYear] = useState();
  const [researchYearContribution, setResearchYearConstribution] = useState();
  const [keyIndustries, setKeyIndustries] = useState([]);
  const [fields, setFields] = useState([]);
  const [researchArea, setResearchArea] = useState();
  const [keyGeography, setKeyGeography] = useState();
  const [keyGeographies, setKeyGeographies] = useState([]);
  const [keyPlayers, setKeyPlayers] = useState([]);
  const [keyPlayer, setKeyPlayer] = useState();
  const [keyInventors, setKeyInventors] = useState();
  const [loadMoreText, setLoadMoreText] = useState("Load More");
  const [grantPercent, setGrantPercent] = useState();
  const [insights, setInsights] = useState();
  const [topCountryInfo, setTopCountryInfo] = useState();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);
  const [pageHeading, setPageHeading] = useState("Searching...");
  const [resetFilters, setResetFilters] = useState(false);
  const [thirdCountry, setThirdCountry] = useState("India");
  const [fourthCountry, setFourthCountry] = useState("TW");
  const [fifthCountry, setFifthCountry] = useState("UK")
  const [totalOrganisations, setTotalOrganisations] = useState("536");
  const [organisationCount1, setOrganisationCount1] = useState("234");
  const [organisationCount2, setOrganisationCount2] = useState("130");

  const searchURL =
    "https://hiykgsfdzd.execute-api.us-east-1.amazonaws.com/production/xsearch";
  const cachedDataURL =
    "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/cache/results";
  const statusURL =
    "https://09rypb92vg.execute-api.ap-south-1.amazonaws.com/alpha/api/v1/status-trends";
  const grantRateURL =
    "https://api.incubig.org/analytics/grant-rate";
  const cpcDefinitionAPI = "https://api.incubig.org/analytics/cpc-definition";

  const trackingAPI = "https://api.incubig.org/authorization/track/usage";

  useEffect(() => {
    if (location.state && location.state.cache) {
      cachedSearch(location.state.query);
    } else if (location.state) {
      directSearch(
        query,
        createFilters(location.state.filter, location.state.value)
      );
    } else {
      directSearch(query);
    }
  }, [window.location.search]);

  const directSearch = (query, filter = null, latestFirst = false) => {
    clearState();
    setPageHeading("Searching...");
    axios
      .get(searchURL, {
        params: { query: query, filter: filter, cutoff: cutoff },
      })
      .then((response) => {
        // console.log(response.data)
        setState(response.data, (latestFirst = latestFirst), filter);
        trackUsage("search",{query:query},response.data.facets.main_cpc.length>0?response.data.facets.main_cpc[0][0]:null);
      })
      .catch((error) => {
        console.log(error);
        setPageHeading("No results found, please refine your query");
      });
  
  };

  const cachedSearch = (query) => {
    if (typeof cachedResults != "undefined") {
      setState(cachedResults[query]);
    } else {
      directSearch(query);
    }
  };

  const setState = (data, latestFirst = false, filter) => {
    if (data.count !== 0) {
      setSearchStatus(true);
      var tempPageHeading =
        "DYR " +
        (filter !== null
          ? " (" + toTitleCase(getParamsFromFilter(filter)) + " )"
          : "");
      setPageHeading(tempPageHeading);
    } else {
      setPageHeading("No results found, please refine your query");
    }
    setKeyGeography(ISOCode[data.facets.assignee_country[0][0]]);
    generateInsights(
      data.facets.assignee_country,
      data.facets.assignee);
    setKeyGeographies(data.facets.assignee_country.filter(item => item[0] !== null));
    setApplicationCount(data.count);
    if (latestFirst) {
      setKeyApplications(
        data.results.sort((a, b) =>
          a.application_year > b.application_year ? -1 : 1
        )
      );
    } else {
      setKeyApplications(data.results);
    }

    let tempPublicationNumbers = [];
    // let base64regex =
    //   /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    
    data.results.map((item) => {
      let padding = item.publication_number.at(-1)
      let tempPublicationNumber = item.publication_number;
      if (item.publication_number.length == 11) {
        tempPublicationNumber = item.publication_number
      }
      else {
        tempPublicationNumber = item.publication_number.substr(0,item.publication_number.length-1) + '='.repeat(padding);
      }
      try{
        if (tempPublicationNumber.length == 11) {
          console.log("US",tempPublicationNumber)
          tempPublicationNumber = tempPublicationNumber
        }
        else {
          tempPublicationNumber = base64.decode(tempPublicationNumber)
        }
      }
      catch(error) {
        console.log(error)
        tempPublicationNumber = tempPublicationNumber
      }
      tempPublicationNumbers.push(tempPublicationNumber)
      // let tempPublicationNumber = "";
      // if (base64regex.test(item.publication_number)) {
      //   console.log(item.publication_number);
      //   tempPublicationNumber = base64.decode(
      //     item.publication_number,
      //     "base64"
      //   );
      // } else {
      //   console.log(item.publication_number, "not base64");
      //   tempPublicationNumber = item.publication_number;
      // }
      // tempPublicationNumbers.push(
      //   tempPublicationNumber.substring(0, tempPublicationNumber.length - 1)
      // );
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: grantRateURL,
      headers: {
        "Content-Type": "application/json",
        "x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"
      },
      data: tempPublicationNumbers,
    };
    axios
      .request(config)
      .then((response) => {
        setGrantPercent(response.data.grant_rate);
      })
      .catch((error) => {
        console.log(error);
      });
    setTrends(data.trends);
    setMaximaMinima(localMaximaMinima(data.trends));
    setKeyIndustries(data.facets.industry.filter((item) => item[0] !== null));
    if (data.facets.main_cpc.length > 0){
      let encodedCPC = encodeURIComponent(window.btoa(data.facets.main_cpc[0][0]))
      localStorage.setItem("technology", encodedCPC);
    }
    let cleanedCPC = data.facets.main_cpc.filter((item)=>item[0]!==null)
    axios
      .post(
        cpcDefinitionAPI,
        cleanedCPC.map((item) => {
          return item[0];
        })
      )
      .then((resp) => {
        let tempFields = [];
        cleanedCPC.forEach((item) => {
          tempFields.push({
            cpc: item[0],
            count: item[1],
            definition: resp.data.filter((i) => i.cpc == item[0])[0].definition,
          });
        });
        setFields(tempFields);
        setResearchArea(tempFields[0].definition);
      });

    setKeyPlayers(removeDuplicates(data.facets.assignee));
    if (data.facets.assignee.length > 0){
      setKeyPlayer(data.facets.assignee[0][0]);
      localStorage.setItem("assignee", data.facets.assignee[0][0]);
    }
    let tempResearchYearCount = 0;
    let tempResearchYearIndex = 0;
    let applicationCount = 0;
    data.trends.count.forEach((item, index) => {
      applicationCount += item[0];
      if (tempResearchYearCount < item[0]) {
        tempResearchYearCount = item[0];
        tempResearchYearIndex = index;
      }
    });
    setKeyInventors(data.facets.inventors);
    setResearchYear(data.trends.labels[tempResearchYearIndex]);
    setResearchYearConstribution(tempResearchYearCount);
    getTopCountryInfo(data.facets.assignee_country[0][0]);
    saveUserPreference(
      cleanedCPC.map((item) => {
        return item[0];
      })
    );
  };

  const clearState = () => {
    setSearchStatus(false);
    setKeyApplications();
    setTrends();
    setResearchYear();
    setResearchYearConstribution();
    setKeyGeographies();
    setKeyIndustries();
    setKeyPlayers();
    setKeyInventors();
    setInsights();
    setTopCountryInfo();
    setGrantPercent();
  };

  const generateInsights = (
    countries,
    assignees
    ) => {
    setInsights(
      <div>
        <p className="para" style={{ marginTop: "20px" }}>
          In recent 1000 patents, The{" "}
          <b className="globalStrong">{(countries&&countries.length>0)?ISOCode[countries[0][0]]:"United States"}</b> is at
          the forefront, with{" "}
          <b className="globalStrong">{(countries&&countries.length>1)?ISOCode[countries[1][0]]:"China"}</b> and &nbsp;
          <b className="globalStrong">{(countries&&countries.length>2)?ISOCode[countries[2][0]]:"India"}</b>&nbsp; following closely.
        </p>

        <div className="trendText" style={{ marginTop: "10px" }}>
          <ul className="keyPointsUL">
            <li style={{ marginBottom: "10px" }}>
              <div className="keyPointsLI">
                <strong
                  className="keyPointsLI"
                  style={{ color: "black" }}
                >
                  {(countries&&countries.length>3)?ISOCode[countries[3][0]]:"Japan"}
                </strong> and  <strong
                  className="keyPointsLI"
                  style={{ color: "black" }}
                >
                  {(countries&&countries.length>4)?ISOCode[countries[4][0]]:"South Korea"}
                </strong>
                &nbsp;rank among the top five countries.
              </div>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <div className="keyPointsLI">
                Globally, a total of <strong
                  className="keyPointsLI"
                  style={{ color: "black" }}
                >
                  {assignees.length}
                </strong> organisations are active.
              </div>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <div className="keyPointsLI"> In
                <strong
                  className="keyPointsLI"
                  style={{ color: "black" }}
                >
                  &nbsp;{(countries&&countries.length>0)?ISOCode[countries[0][0]]:"United States"}
                </strong>
                a total of  <strong
                  className="keyPointsLI"
                  style={{ color: "black" }}
                >
                  {(countries&&countries.length>0)?countries[0][1]:54}
                </strong> patent applications were filed.
              </div>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <div className="keyPointsLI"> In
                <strong
                  className="keyPointsLI"
                  style={{ color: "black" }}
                >
                  &nbsp;{(countries&&countries.length>1)?ISOCode[countries[1][0]]:"China"}
                </strong>
                a total of  <strong
                  className="keyPointsLI"
                  style={{ color: "black" }}
                >
                  {(countries&&countries.length>1)?countries[1][1]:32}
                </strong> patent applications were filed.
              </div>
            </li>
          </ul>
        </div>

       
      </div>
    )
  };

  const getTopCountryInfo = (country, maxCount = 5) => {
    setTopCountryInfo();
    axios
      .get(searchURL, {
        params: {
          query: query,
          filter: createFilters("assignee_country", country),
        },
      })
      .then((response) => {
        // response.data.facets.assignee.forEach((item)=>{

        // })
        setTopCountryInfo({
          country: country,
          assignees: removeDuplicates(
            response.data.facets.assignee.slice(0, maxCount)
          ),
          inventors: response.data.facets.inventors,
        });
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      directSearch(query);
    }
  };

  const localMaximaMinima = (trends) => {
    let arr = trends.count.map((item) => {
      return item[0];
    });
    // Empty vector to store points of local maxima and minima
    let mx = [],
      mn = [];

    // Checking whether the first point is local maxima or minima or none
    if (arr[0] > arr[1]) {
      mx.push(trends.labels[0]);
    } else if (arr[0] < arr[1]) {
      mn.push(trends.labels[0]);
    }

    // Iterating over all points to check local maxima and local minima
    for (let i = 1; i < arr.length - 1; i++) {
      // Condition for local minima
      if (arr[i - 1] > arr[i] && arr[i] < arr[i + 1]) mn.push(trends.labels[i]);
      // Condition for local maxima
      else if (arr[i - 1] < arr[i] && arr[i] > arr[i + 1])
        mx.push(trends.labels[i]);
    }

    // Checking whether the last point is local maxima or minima or none
    if (arr[arr.length - 1] > arr[arr.length - 2]) {
      mx.push(trends.labels[arr.length - 1]);
    } else if (arr[arr.length - 1] < arr[arr.length - 2]) {
      mn.push(trends.labels[arr.length - 1]);
    }
    mx.length = Math.min(mx.length, 3);
    mn.length = Math.min(mn.length, 3);
    return { maxima: mx, minima: mn };
  };

  return (
    <div className="searchResult">
      <PersonalizedHeader />
      <SearchPageHeader
        onClick={handleEnter}
        searchFunction={(query, filter) => {
          directSearch(query, filter);
        }}
        resetFilters={resetFilters}
      />

      <hr className="thin-horizontal-line" />

      <span className="headingSearch" style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.75rem",
              background: "linear-gradient(to right, #1834dd, #d079df)",
              color: "white",
              height: "40px",
            }}>
        {pageHeading.includes("DYR")
          ? pageHeading +
            " | " +
            toTitleCase(window.location.pathname.split("/").at(-1))
          : pageHeading}
      </span>

      {(window.location.pathname.split("/").at(-1) == "dataset") |
      (window.location.pathname.split("/").at(-1) == "results") ? (
        <div>
          <hr className="thin-horizontal-line" />
          <DataSet
            query={query}
            searchFunction={directSearch}
            keyApplications={removeDuplicatePatents(keyApplications)}
            searchStatus={searchStatus}
            keyPlayers={keyPlayers}
            keyGeographies={keyGeographies}
            keyInventors={keyInventors}
            topYear={researchYear}
            topYearContribution={researchYearContribution}
            grantPercent={grantPercent}
          />
          <hr className="thin-horizontal-line" />
        </div>
      ) : (
        <div></div>
      )}
      {window.location.pathname.split("/").at(-1) == "trends" && (
        <div>
          <hr className="thin-horizontal-line" />
          <Trends
            keyPlayers={keyPlayers}
            researchArea={researchArea}
            topYear={researchYear}
            topYearContribution={researchYearContribution}
            grantPercent={grantPercent}
            trends={trends}
          />
          <hr className="thin-horizontal-line" />
        </div>
      )}
      {window.location.pathname.split("/").at(-1) == "global" && (
        <div>
          <hr className="thin-horizontal-line" />
          <Global
            query={query}
            insights={insights}
            searchStatus={searchStatus}
            keyPlayers={keyPlayers}
            keyGeographies={keyGeographies}
            keyInventors={keyInventors}
            grantPercent={grantPercent}
            topCountryInfo={topCountryInfo}
          />
          <hr className="thin-horizontal-line" />
        </div>
      )}
      {window.location.pathname.split("/").at(-1) == "technology" && (
        <div>
          <hr className="thin-horizontal-line" />
          <SearchResultsTechnologies 
            technologies={fields} 
            industries={keyIndustries}
          />
          <hr className="thin-horizontal-line" />
        </div>
      )}

      <Row className="g-0">
        <Col xs="12">
          <div style={{ height: "20px" }}></div>
        </Col>
      </Row>

      <Row className="g-0">
        <Footer />
      </Row>
    </div>
  );
};

export default SearchResult;
